export const defaultThemes = {
    newGame: "light",
    loadGame: "light",
    createPiece: "dark",
    customize: "dark",
    gameRoot: "dark",
    myPieces: "light",
    councilRules: "tan",
    home: "light",
};
