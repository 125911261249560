export const sampleImgs = {
    "Octocat-black-svg": "/Images/Pieces/Samples/Octocat-black.svg",
    "Octocat-white-svg": "/Images/Pieces/Samples/Octocat-white.svg",
    "black_firehat-svg": "/Images/Pieces/Samples/black_firehat.svg",
    "docker-red-svg": "/Images/Pieces/Samples/docker-red.svg",
    "docker-svg": "/Images/Pieces/Samples/docker.svg",
    "dragon-white-svg": "/Images/Pieces/Samples/dragon-white.svg",
    "dragon-black-svg": "/Images/Pieces/Samples/dragon-black.svg",
    "evil_morty_blue-svg": "/Images/Pieces/Samples/evil_morty_blue.svg",
    "evil_morty_white-svg": "/Images/Pieces/Samples/evil_morty_white.svg",
    "evil_morty_red-svg": "/Images/Pieces/Samples/evil_morty_red.svg",
    "material-ui-red-svg": "/Images/Pieces/Samples/material-ui-red.svg",
    "node-green-png": "/Images/Pieces/Samples/node-green.png",
    "white_firehat-svg": "/Images/Pieces/Samples/white_firehat.svg",
    "material-ui-svg": "/Images/Pieces/Samples/material-ui.svg",
    "node-red-png": "/Images/Pieces/Samples/node-red.png",
    "scream-png": "/Images/Pieces/Samples/scream.png",
};

export const sampleImgList = [
    "/Images/Pieces/Samples/Octocat-black.svg",
    "/Images/Pieces/Samples/Octocat-white.svg",
    "/Images/Pieces/Samples/black_firehat.svg",
    "/Images/Pieces/Samples/docker-red.svg",
    "/Images/Pieces/Samples/docker.svg",
    "/Images/Pieces/Samples/dragon-white.svg",
    "/Images/Pieces/Samples/dragon-black.svg",
    "/Images/Pieces/Samples/evil_morty_blue.svg",
    "/Images/Pieces/Samples/evil_morty_white.svg",
    "/Images/Pieces/Samples/evil_morty_red.svg",
    "/Images/Pieces/Samples/material-ui-red.svg",
    "/Images/Pieces/Samples/node-green.png",
    "/Images/Pieces/Samples/white_firehat.svg",
    "/Images/Pieces/Samples/material-ui.svg",
    "/Images/Pieces/Samples/node-red.png",
    "/Images/Pieces/Samples/scream.png",
];
